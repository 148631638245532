import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Categories = () => import('./views/Index.vue');
const CategoryView = () => import('./views/CategoryView.vue');

export default [
  {
    path: '/categories',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Categories',
        component: Categories,
        meta: { title: i18n.global.t('categories.categories') },
      },
      {
        path: 'view/:id?',
        name: 'Categories.View',
        component: CategoryView,
        meta: { title: i18n.global.t('categories.view'), pageType: 'view' },
        props: true,
      },
      {
        path: 'update/:id?',
        name: 'Categories.Update',
        component: CategoryView,
        meta: { title: i18n.global.t('categories.update'), pageType: 'update' },
        props: true,
      },
    ],
  },
];
