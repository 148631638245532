import store from '@/state/store';

export const checkPermission = (key) => {
  const userData = store.getters['authUser/userData'];
  const permissions = userData?.role?.permissions || [];
  return permissions?.findIndex((i) => i.name == key) > -1 ? true : false;
};

export const dashboard = {
  showStatistics: 'show-statistics',
};

export const users = {
  showAll: 'show-all-users',
  showOne: 'show-user',
  create: 'create-user',
  update: 'edit-user',
  delete: 'delete-user',
  createTransaction: 'manage-wallet',
  assignUsers: 'assign-users',
};

export const visits = {
  advisor: {
    showAll: 'show-all-advisors-visits',
    showOne: 'show-advisor-visit',
    create: 'create-advisor-visit',
    update: 'edit-advisor-visit',
    delete: 'delete-advisor-visit',
  },
  'productivity-check-employee': {
    showAll: 'show-all-productivity-check-employee-visits',
    showOne: 'show-productivity-check-employee-visit',
    create: 'create-productivity-check-employee-visit',
    update: 'edit-productivity-check-employee-visit',
    delete: 'delete-productivity-check-employee-visit',
  },
  expert: {
    showAll: 'show-all-experts-visits',
    showOne: 'show-expert-visit',
    // create: 'create-expert-visit',
    // update: 'edit-expert-visit',
    // delete: 'delete-expert-visit',
    assignExpert: 'assign-expert-to-visit',
  },
  myVisits: 'my-visits',
};

export const tracks = {
  showAll: 'show-all-tracks',
  showOne: 'show-track',
  create: 'create-track',
  update: 'edit-track',
  delete: 'delete-track',

  showMedia: 'track-media',
  createMedia: 'track-media-create',
  updateMedia: 'track-media-edit',
  deleteMedia: 'track-media-delete',
};

export const applicants = {
  showAll: 'show-all-applicants',
  // showOne: 'show-applicant',
  // create: 'create-applicant',
  // update: 'edit-applicant',
  // delete: 'delete-applicant',

  accepte: 'applicant-accepted',
  rejecte: 'applicant-rejected',
  shortlist: 'applicant-shortlist',
  // deleteCV: 'delete-cv',
};

export const questions = {
  showAll: 'show-all-questions',
  showOne: 'show-question',
  create: 'create-question',
  update: 'edit-question',
  delete: 'delete-question',
  // deleteAnswer: 'delete-question-answer',
};

export const jobs = {
  showAll: 'show-all-jobs',
  showOne: 'show-job',
  create: 'create-job',
  update: 'edit-job',
  delete: 'delete-job',
};

export const dates = {
  showAll: 'show-all-dates-types',
  showOne: 'show-dates-type',
};

export const roles = {
  showAll: 'show-all-roles',
  showOne: 'show-role',
  create: 'create-role',
  update: 'edit-role',
  delete: 'delete-role',
};

export const farms = {
  showAll: 'show-all-farms',
  showOne: 'show-farm',
  create: 'create-farm',
  update: 'update-farm',
  delete: 'delete-farm',
};

export const rewards = {
  show: 'show-rewards',
  update: 'edit-rewards',
  give: 'give-rewards',
};

export default {
  checkPermission,
  dashboard,
  users,
  visits,
  tracks,
  applicants,
  questions,
  jobs,
  dates,
  roles,
  farms,
  rewards,
};
