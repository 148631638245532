import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Admins = () => import('./views/Index.vue');
const AdminView = () => import('./views/AdminView.vue');

export default [
  {
    path: '/admins',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Admins',
        component: Admins,
        meta: { title: i18n.global.t('admins.admins') },
      },
      {
        path: 'view/:id?',
        name: 'Admins.View',
        component: AdminView,
        meta: { title: i18n.global.t('admins.view'), pageType: 'view' },
        props: true,
      },
      {
        path: 'create',
        name: 'Admins.Create',
        component: AdminView,
        meta: { title: i18n.global.t('admins.create'), pageType: 'create' },
      },
      {
        path: 'update/:id?',
        name: 'Admins.Update',
        meta: { title: i18n.global.t('admins.update'), pageType: 'update' },
        component: AdminView,
        props: true,
      },
    ],
  },
];
