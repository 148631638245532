import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const MarketplaceCategories = () => import('./views/Index.vue');
const MarketplaceCategoryView = () => import('./views/MarketplaceCategoryView.vue');

export default [
  {
    path: '/marketplace-categories',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: 'products',
        name: 'MarketplaceCategoriesProducts',
        component: MarketplaceCategories,
        meta: { title: i18n.global.t('marketplaceCategories.products'), pageType: 'products' },
      },
      {
        path: 'services',
        name: 'MarketplaceCategoriesServices',
        component: MarketplaceCategories,
        meta: { title: i18n.global.t('marketplaceCategories.services'), pageType: 'services' },
      },

      {
        path: 'view/:subType?/:id?',
        name: 'MarketplaceCategoriesProducts.View',
        component: MarketplaceCategoryView,
        meta: { title: i18n.global.t('marketplaceCategories.view'), pageType: 'view' },
        props: true,
      },
      {
        path: 'create/:subType?',
        name: 'MarketplaceCategoriesProducts.Create',
        component: MarketplaceCategoryView,
        meta: { title: i18n.global.t('marketplaceCategories.create'), pageType: 'create' },
      },
      {
        path: 'update/:subType?/:id?',
        name: 'MarketplaceCategoriesProducts.Update',
        component: MarketplaceCategoryView,
        meta: { title: i18n.global.t('marketplaceCategories.update'), pageType: 'update' },
        props: true,
      },
    ],
  },
];
