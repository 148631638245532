import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Reviews = () => import('./views/Index.vue');

export default [
  {
    path: '/reviews',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Reviews',
        component: Reviews,
        meta: { title: i18n.global.t('reviews.reviews') },
      },
    ],
  },
];
