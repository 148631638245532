import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Helps = () => import('./views/Index.vue');
const HelpView = () => import('./views/HelpView.vue');

export default [
  {
    path: '/helps',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Helps',
        component: Helps,
        meta: { title: i18n.global.t('helps.helps') },
      },
      {
        path: 'view/:id?',
        name: 'Helps.View',
        component: HelpView,
        meta: { title: i18n.global.t('helps.view') },
        props: true,
      },
    ],
  },
];
