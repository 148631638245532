import errorsRoutes from '../modules/errors/errors.routes';

import aboutUsRoutes from '../modules/aboutUs/aboutUs.routes';
import adminsRoutes from '../modules/admins/admins.routes';
import adsRoutes from '../modules/ads/ads.routes';
import authRoutes from '../modules/auth/auth.routes';
import bankAccountRoutes from '../modules/bankAccount/bankAccount.routes';
import brandsRoutes from '../modules/brands/brands.routes';
import categoriesRoutes from '../modules/categories/categories.routes';
import companiesRoutes from '../modules/companies/companies.routes';
import dashboardRoutes from '../modules/dashboard/dashboard.routes';
import deliveryFeesRoutes from '../modules/deliveryFees/deliveryFees.routes';
import familyRoutes from '../modules/family/family.routes';
import feedbackQuestionRoutes from '../modules/feedbackQuestion/feedbackQuestion.routes';
import helpsRoutes from '../modules/helps/helps.routes';
import linksRoutes from '../modules/links/links.routes';
import loyaltyPointRoutes from '../modules/loyaltyPoint/loyaltyPoint.routes';
import mainCategoriesRoutes from '../modules/mainCategories/mainCategories.routes';
import marketplaceCategoriesRoutes from '../modules/marketplaceCategories/marketplaceCategories.routes';
import marketplaceProceedProductsRoutes from '../modules/marketplaceProceedProducts/marketplaceProceedProducts.routes';
import marketplaceProceedServicesRoutes from '../modules/marketplaceProceedServices/marketplaceProceedServices.routes';
import marketplaceProductsRoutes from '../modules/marketplaceProducts/marketplaceProducts.routes';
import marketplaceServicesRoutes from '../modules/marketplaceServices/marketplaceServices.routes';
import marketplaceProductsLogsRoutes from '../modules/marketplaceProductsLogs/marketplaceProductsLogs.routes';
import marketplaceServicesLogsRoutes from '../modules/marketplaceServicesLogs/marketplaceServicesLogs.routes';
import notificationsRoutes from '../modules/notifications/notifications.routes';
import officesDeliveryScheduleRoutes from '../modules/officesDeliverySchedule/officesDeliverySchedule.routes';
import ordersRoutes from '../modules/orders/orders.routes';
import privacyPolicyRoutes from '../modules/privacyPolicy/privacyPolicy.routes';
import promoCodesRoutes from '../modules/promoCodes/promoCodes.routes';
import reportsRoutes from '../modules/reports/reports.routes';
import reviewsRoutes from '../modules/reviews/reviews.routes';
import stocksRoutes from '../modules/stocks/stocks.routes';
import supportRoutes from '../modules/support/support.routes';
import surveysRoutes from '../modules/surveys/surveys.routes';
import surveysImageRoutes from '../modules/surveysImage/surveysImage.routes';
import termsAndConditionsRoutes from '../modules/termsAndConditions/termsAndConditions.routes';
import trendingNowRoutes from '../modules/trendingNow/trendingNow.routes';
import usersRoutes from '../modules/users/users.routes';

export default [
  ...errorsRoutes,

  ...aboutUsRoutes,
  ...adminsRoutes,
  ...adsRoutes,
  ...authRoutes,
  ...bankAccountRoutes,
  ...brandsRoutes,
  ...categoriesRoutes,
  ...companiesRoutes,
  ...dashboardRoutes,
  ...deliveryFeesRoutes,
  ...familyRoutes,
  ...feedbackQuestionRoutes,
  ...helpsRoutes,
  ...linksRoutes,
  ...loyaltyPointRoutes,
  ...mainCategoriesRoutes,
  ...marketplaceCategoriesRoutes,
  ...marketplaceProceedProductsRoutes,
  ...marketplaceProceedServicesRoutes,
  ...marketplaceProductsRoutes,
  ...marketplaceServicesRoutes,
  ...marketplaceProductsLogsRoutes,
  ...marketplaceServicesLogsRoutes,
  ...notificationsRoutes,
  ...officesDeliveryScheduleRoutes,
  ...ordersRoutes,
  ...privacyPolicyRoutes,
  ...promoCodesRoutes,
  ...reportsRoutes,
  ...reviewsRoutes,
  ...stocksRoutes,
  ...supportRoutes,
  ...surveysRoutes,
  ...surveysImageRoutes,
  ...termsAndConditionsRoutes,
  ...trendingNowRoutes,
  ...usersRoutes,
];
