import PageHeader from '@/components/page-header';
import MainTable from '@/components/table/MainTable.vue';
import SubmitFooter from '@/components/SubmitFooter.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LoadingData from '@/components/LoadingData.vue';
import NoResult from '@/components/NoResult.vue';

// inputs
import MainInput from '@/components/inputs/MainInput.vue';
import MainSelect from '@/components/inputs/MainSelect.vue';
import MainCheckbox from '@/components/inputs/MainCheckbox.vue';
import MainTextarea from '@/components/inputs/MainTextarea.vue';
import MainDateTimePickr from '@/components/inputs/MainDateTimePickr.vue';
import HijriDatePickr from '@/components/inputs/HijriDatePickr.vue';
import MainFileInput from '@/components/inputs/MainFileInput.vue';
import MainTagsInput from '@/components/inputs/MainTagsInput.vue';

// buttons
import AddItem from '@/components/buttons/AddItem.vue';
import RemoveItem from '@/components/buttons/RemoveItem.vue';

// vee-validate
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate';

export default {
  install: (app) => {
    app
      .component('PageHeader', PageHeader)
      .component('MainTable', MainTable)
      .component('SubmitFooter', SubmitFooter)
      .component('LoadingSpinner', LoadingSpinner)
      .component('LoadingData', LoadingData)
      .component('NoResult', NoResult)
      .component('MainInput', MainInput)
      .component('MainSelect', MainSelect)
      .component('MainCheckbox', MainCheckbox)
      .component('MainTextarea', MainTextarea)
      .component('MainDateTimePickr', MainDateTimePickr)
      .component('HijriDatePickr', HijriDatePickr)
      .component('MainFileInput', MainFileInput)
      .component('MainTagsInput', MainTagsInput)
      .component('AddItem', AddItem)
      .component('RemoveItem', RemoveItem)
      .component('ValidationForm', ValidationForm)
      .component('Field', Field)
      .component('ErrorMessage', ErrorMessage);
  },
};
