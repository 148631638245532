import axios from 'axios';
import { customAlert } from '@/utils/sweetalert';
import { API_BASE_URL } from '../config/constant';
import router from '@/router/index';
import store from '@/state/store';

const API_TIMEOUT = 20000;

// const language = { value: 'ar' };

export const apiHeader = () => {
  return {
    Authorization: store.getters['authUser/userToken'] ? `Bearer ${store.getters['authUser/userToken']}` : null,
    Accept: 'application/json',
    ContentType: 'application/json',
    // lang: language.value,
  };
};

export const apiResponseCatch = (error) => {
  const status = error?.response?.status ?? 0;

  if (status == 401) {
    customAlert(error?.response?.data?.message ?? 'API Error', 'error');
    store.dispatch('authUser/setUserToken', null);
    store.dispatch('authUser/setUserData', null);
    router.push('/');
    // } else if (status == 422) {
    //   window.scrollTo(0, 0);
  } else if (status != 422) {
    customAlert(error?.response?.data?.message ?? 'API Error', 'error');
  }

  return Promise.reject(error);
};

export default () => {
  const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: apiHeader(),
    timeout: API_TIMEOUT,
  });
  apiClient.interceptors.response.use((response) => {
    return response;
  }, apiResponseCatch);
  return apiClient;
};
