const Layout = () => import('@/layouts/main.vue');
const NoPermission = () => import('./views/NoPermission.vue');
const Error404 = () => import('./views/Error404.vue');

export default [
  {
    path: '/errors',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: 'no-permission',
        name: 'Errors.NoPermission',
        component: NoPermission,
        meta: { title: 'الصلاحيات' },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error404',
    component: Error404,
    meta: { title: 'الصفحة غير موجودة' },
  },
];
