import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const PrivacyPolicy = () => import('./views/Index.vue');

export default [
  {
    path: '/privacy-policy',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: { title: i18n.global.t('settings.privacyPolicy') },
      },
    ],
  },
];
