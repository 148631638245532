import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Brands = () => import('./views/Index.vue');
const BrandView = () => import('./views/BrandView.vue');

export default [
  {
    path: '/brands',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Brands',
        component: Brands,
        meta: { title: i18n.global.t('brands.brands') },
      },
      {
        path: 'view/:id?',
        name: 'Brands.View',
        component: BrandView,
        meta: { title: i18n.global.t('brands.view'), pageType: 'view' },
        props: true,
      },
      {
        path: 'update/:id?',
        name: 'Brands.Update',
        component: BrandView,
        meta: { title: i18n.global.t('brands.update'), pageType: 'update' },
        props: true,
      },
    ],
  },
];
