import i18n from '@/i18n';
import { dashboard } from '../../utils/permission';
const Layout = () => import('@/layouts/main.vue');
const Dashboard = () => import('./views/Index.vue');

export default [
  {
    path: '/dashboard',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: { title: i18n.global.t('main.dashboard'), permission: dashboard.showStatistics },
      },
    ],
  },
];
