import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Reports = () => import('./views/Index.vue');

export default [
  {
    path: '/reports',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Reports',
        component: Reports,
        meta: { title: i18n.global.t('reports.reports') },
      },
    ],
  },
];
