import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Family = () => import('./views/Index.vue');

export default [
  {
    path: '/family',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Family',
        component: Family,
        meta: { title: i18n.global.t('family.family') },
      },
    ],
  },
];
