import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const MainCategories = () => import('./views/Index.vue');
const MainCategoryView = () => import('./views/MainCategoryView.vue');

export default [
  {
    path: '/main-categories',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'MainCategories',
        component: MainCategories,
        meta: { title: i18n.global.t('mainCategories.mainCategories') },
      },
      {
        path: 'view/:id?',
        name: 'MainCategories.View',
        component: MainCategoryView,
        meta: { title: i18n.global.t('mainCategories.view'), pageType: 'view' },
        props: true,
      },
      {
        path: 'update/:id?',
        name: 'MainCategories.Update',
        component: MainCategoryView,
        meta: { title: i18n.global.t('mainCategories.update'), pageType: 'update' },
        props: true,
      },
    ],
  },
];
