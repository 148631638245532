import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const MarketplaceProceedProducts = () => import('./views/Index.vue');

export default [
  {
    path: '/marketplace-proceed-products',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'MarketplaceProceedProducts',
        component: MarketplaceProceedProducts,
        meta: { title: i18n.global.t('marketplaceProceedProducts.products') },
      },
    ],
  },
];
